import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import useTenantSignedInContext from "../hooks/useTenantSignedInContext"
import useShowHideModalContext from "./useShowHideModalContext"

import { onSignUpClickStart } from "./useSignUpClickStart"
import { segmentAction } from "../hooks/useSegmentTrack"

import { ButtonSecondary, ButtonPrimary } from "./Buttons"
import Logo from "../icons/IconLogo"
import PageNavAccount from "./PageNavAccount"

const Spacer = styled.div`
  height: 68px; /* Should match height of sticky nav */
  @media screen and (max-width: 900px) {
    display: none;
  }
`

const Nav = styled.nav`
  position: fixed;
  font-size: 0.875rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  top: 0;
  z-index: 100000;
  background: #fff;
  width: 100%;
  padding: calc(var(--content-pad) / 2) 0;
  font-weight: bold;
  color: #222;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  height: 75px;

  a {
    margin-bottom: 0;
  }
  svg {
    height: 27px;
    width: auto;
  }
  .navigation-menu {
    ul {
      margin-top: 0.2em;

      a {
        line-height: 0;
        position: relative;
        color: inherit;
        &:after {
          transition: 0.125s;
          content: "";
          width: 100%;
          height: 4px;
          background: var(--sparkle-red);
          position: absolute;
          left: 0px;
          bottom: -8px;
          transform-origin: 0 center;
          transform: scaleX(0);
        }
      }
      a:hover:after {
        transform: scaleX(100%);
      }
      display: flex;
      align-items: center;
      li {
        list-style: none;
        margin-left: 1.5rem;

        &.nav-item:first-of-type {
          margin-left: 0;
        }
      }
    }
    .signup-mobile,
    .login-mobile {
      display: none;
    }
  }
  .cta {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
  }
  .hamburger {
    width: 35px;
    height: 25px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    display: none;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: var(--sparkle-drkblue);
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }
    span:nth-child(1) {
      top: 0px;
    }
    span:nth-child(2),
    span:nth-child(3) {
      top: 10px;
    }
    span:nth-child(4) {
      top: 20px;
    }
  }

  @media screen and (max-width: 900px) {
    box-shadow: none;
    position: initial;
    padding: 1rem 0;

    .login-desktop {
      display: none;
    }
    svg {
      height: 28px;
      width: auto;
    }
    .cta {
      position: unset;
      &.is-expanded {
        .signup-desktop {
          display: none;
        }
      }
    }
    .navigation-menu {
      display: none;
      position: absolute;
      top: 51px;
      left: 0;
      width: 100%;
      height: calc(100vh - 52px);
      background-color: white;
      z-index: 1000;

      ul {
        flex-direction: column;
        margin: 1rem;
        padding: 0 var(--content-pad);

        li {
          width: 100%;
          text-align: left;
          margin: 0;
          padding: 2rem 0;
          border-bottom: 1px solid #ced3db;

          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            border: none;
          }
          a,
          button {
            letter-spacing: 0;
            text-transform: none;
            font-size: 1.25rem;
            font-weight: bold;
          }
        }
      }
      &.is-expanded {
        display: block;
        .signup-mobile,
        .login-mobile {
          display: block;
        }
      }
    }
    .hamburger {
      display: block;

      &.is-expanded {
        display: block;
        span:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
        span:nth-child(2) {
          transform: rotate(45deg);
        }
        span:nth-child(3) {
          transform: rotate(-45deg);
        }
        span:nth-child(4) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
      }
    }

    ${ButtonSecondary}, ${ButtonPrimary} {
      font-size: 0.8rem;
    }
  }
  @media screen and (max-width: 375px) {
    .cta {
      .signup-desktop {
        display: none;
      }
    }
  }
`

const PageNav = ({ links = [] }) => {
  const {
    tenantSignedIn = {},
    loading,
    onLogIn,
    onSignUp,
  } = useTenantSignedInContext()
  const tenantIsSignedIn = tenantSignedIn?.tenantName && !loading
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const [isAccountMobileExpanded, setisAccountMobileExpanded] = useState(false)

  const [modal, setModal] = useShowHideModalContext()
  return (
    <>
      <Spacer id={"top"} />
      <Nav>
        <div
          style={{
            margin: "0 auto",
            padding: "0 var(--content-pad)",
            flex: "900px 0 1",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <Link
            to={"/"}
            style={{ lineHeight: 0 }}
            onClick={() =>
              segmentAction.track(`Homepage Nav Clicked`, { value: "logo" })
            }
          >
            <Logo
              markProps={{ fill: "var(--sparkle-red)" }}
              alt={"We Sparkle"}
            />
          </Link>
          <div
            className={
              isNavExpanded ? "navigation-menu is-expanded" : "navigation-menu"
            }
          >
            <ul>
              {!tenantIsSignedIn && (
                <li className="login-mobile">
                  <button
                    onClick={() => {
                      segmentAction.track("Homepage Login Click")
                      onLogIn()
                    }}
                  >
                    Login →
                  </button>
                </li>
              )}
              {links.map(({ name, href }, i) => (
                <li className="nav-item" key={i}>
                  <Link
                    as={"a"}
                    to={href}
                    onClick={() =>
                      segmentAction.track(`Homepage Nav Clicked`, {
                        value: name,
                      })
                    }
                  >
                    {name}
                  </Link>
                </li>
              ))}
            </ul>
            {!tenantIsSignedIn && (
              <ButtonPrimary
                className="signup-mobile"
                style={{
                  margin: "0 auto",
                  fontSize: "1rem",
                }}
                onClick={() => {
                  segmentAction.track("Homepage Nav New Account Click")
                  // onSignUp()
                  setModal(true)
                }}
              >
                Sign Up
              </ButtonPrimary>
            )}
          </div>
          <div className={isNavExpanded ? "cta is-expanded" : "cta"}>
            <ButtonSecondary
              className="login-desktop"
              onClick={() => {
                segmentAction.track("Homepage Login Click")
                onLogIn()
              }}
              style={{
                marginRight: ".5rem",
                ...(tenantIsSignedIn
                  ? {
                      opacity: 0,
                      pointerEvents: "none",
                      width: 0,
                      transition: "none",
                    } // keep vert space, but hide... just so no vertical jank between button and user icon
                  : {}),
              }}
            >
              Login
            </ButtonSecondary>
            {/* Login btn */}
            <ButtonPrimary
              className="signup-desktop"
              onClick={() => {
                segmentAction.track("Homepage Nav New Account Click")
                onSignUp()
              }}
              style={{
                ...(tenantIsSignedIn
                  ? {
                      opacity: 0,
                      pointerEvents: "none",
                      width: 0,
                      transition: "none",
                    } // keep vert space, but hide... just so no vertical jank between button and user icon
                  : {}),
              }}
            >
              Sign Up
            </ButtonPrimary>
            {/* User logged in icon */}
            {!loading && tenantIsSignedIn && (
              <PageNavAccount
                isNavExpanded={isNavExpanded}
                isAccountMobileExpanded={isAccountMobileExpanded}
                setisAccountMobileExpanded={setisAccountMobileExpanded}
                setIsNavExpanded={setIsNavExpanded}
                trackingPrefix={"Homepage"}
              />
            )}
            <button
              className={isNavExpanded ? "hamburger is-expanded" : "hamburger"}
              onClick={() => {
                setisAccountMobileExpanded(false)
                setIsNavExpanded(!isNavExpanded)
              }}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </Nav>
    </>
  )
}

export default PageNav
