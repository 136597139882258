import React, { useState } from "react"
import axios from "axios"
import styled from "styled-components"
import { ButtonPrimary } from "./Buttons"
import ButtonLoaderWithText from "../core/ButtonLoaderWithText"
import { segmentAction } from "../hooks/useSegmentTrack"

const Form = styled.form`
  margin-top: 2rem;
  margin-bottom: 4rem;
  max-width: 450px;
  button {
    display: block;
  }
  input,
  textarea {
    width: 100%;
    padding: 0.65em 0.75em;
    border-radius: 0.75em;
    font-size: 1.1rem;
    display: block;
    margin-bottom: 0.75em;
    ::placeholder {
      color: #000;
    }
  }
`

const initContact = {
  name: "",
  email: "",
  message: "",
}

const PageContactForm = () => {
  const [fields, setFields] = useState(initContact)
  const [success, setSuccess] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const updateField = (fieldName) => (e) => {
    const value = e.target.value
    setFields((prev) => {
      return { ...prev, [fieldName]: value }
    })
  }
  let contactSubmitText = "Send"
  if (inProgress) contactSubmitText = "Sending"
  if (success) contactSubmitText = "Success!"

  const onSubmit = (e) => {
    e.preventDefault()
    segmentAction.track("Homepage Contact Form Submit")
    setInProgress(true)
    // If success show this stuff + clear out
    // api call
    const baseURL = process.env.GATSBY_SPARKLE_API_PATH
    const apiCode = process.env.GATSBY_SPARKLE_API_CODE
    const api = axios.create({ baseURL })
    api
      .post(`AddLeadHandler?code=${apiCode}`, fields)
      .then((response) => {
        setFields(initContact)
        setSuccess(true)
        // Reset
        setTimeout(() => setSuccess(false), 4000)
        setInProgress(false)
      })
      .catch(() => {
        setSuccess(false)
        setInProgress(false)
      })
  }

  return (
    <Form
      style={
        success || inProgress
          ? { pointerEvents: "none" } // prevent changing data while in flight or showing success
          : {}
      }
      onSubmit={onSubmit}
    >
      <p>
        Questions?
        <br />
        Need a demo?
        <br />
        Interested in an enterprise customization?
      </p>
      <p style={{ position: "relative" }}>
        <span
          style={{
            opacity: !success ? 1 : 0,
            transition: ".2s",
            transform: !success ? "translateY(0)" : "translateY(5px)",
          }}
        >
          Drop us a line!
        </span>
        <span
          style={{
            width: "100%",
            left: 0,
            position: "absolute",
            color: "var(--sparkle-green)",
            opacity: success ? 1 : 0,
            transition: ".2s",
            transform: success ? "translateY(0)" : "translateY(5px)",
          }}
        >
          Hooray! Successfully sent message!
        </span>
      </p>
      <label>
        <input
          type={"text"}
          required
          onChange={updateField("name")}
          placeholder={"Name"}
          value={fields.name}
        />
      </label>
      <label>
        <input
          type={"email"}
          required
          onChange={updateField("email")}
          placeholder={"Email"}
          value={fields.email}
        />
      </label>
      <label>
        <textarea
          rows={5}
          required
          onChange={updateField("message")}
          placeholder={"Message"}
          value={fields.message}
        />
      </label>
      <ButtonPrimary
        disabled={inProgress || success}
        type={"submit"}
        style={{
          width: "100%",
          display: "block",
          fontSize: "1.2rem",
          background: success ? "var(--sparkle-drkgreen)" : "",
        }}
      >
        {contactSubmitText}
        <ButtonLoaderWithText isProcessing={inProgress} />
      </ButtonPrimary>
    </Form>
  )
}

export default PageContactForm
