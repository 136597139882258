import React, { useState, useContext, createContext } from "react"

export const ShowHideModalContext = createContext([])
export const ShowHideModalProvider = ({ children }) => {
  const [show, setShow] = useState(false)
  return (
    <ShowHideModalContext.Provider value={[show, setShow]}>
      {children}
    </ShowHideModalContext.Provider>
  )
}

const useShowHideModalContext = () => {
  const context = useContext(ShowHideModalContext)
  if (context === undefined) {
    // Let's yell at ourselves to make sure we use our Provider wrapper
    throw new Error(
      "Oooops, I'm guessing your forgot to use the Provider for this context"
    )
  }
  return context
}

export default useShowHideModalContext
