import React, { Fragment } from "react"
import IconMN from "./icons/IconMN"
import IconHeart from "../icons/IconHeart"
import styled from "styled-components"
import PageContactForm from "./PageContactForm"
import PageContactNewsletter from "./PageContactNewsletter"
import { segmentAction } from "../hooks/useSegmentTrack"
import { IconLinks } from "../icons/IconLinks"
import { Link } from "gatsby"

const Container = styled.div`
  a {
    color: #fff;
    text-decoration: underline;
    text-underline-offset: 8px;
  }
`

const socials = [
  { url: "https://www.instagram.com/wesparkleco/", name: "instagram" },
  { url: "https://twitter.com/wesparkleco", name: "twitter" },
  { url: "https://www.linkedin.com/company/we-sparkle/", name: "linkedin" },
  { url: "https://www.facebook.com/wesparkleco", name: "facebook" },
  {
    url: "https://www.youtube.com/channel/UCFXtjIagJiqxaBkIB75gPOw/videos",
    name: "youtube",
  },
]

const PageContactInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`
const PageContactSocials = styled.div``
const SocialHover = styled.span`
  svg {
    fill: #fff;
    transition: 0.2s;
  }
  :hover {
    svg {
      fill: var(--sparkle-yellow);
      transform: scale(1.15);
    }
  }
`
const termsElems = [
  <a
    href={"https://helpcenter.wesparkle.ai/article/Terms-&-Conditions"}
    onClick={() =>
      segmentAction.track("Homepage Contact Click", { value: "terms" })
    }
  >
    Terms
  </a>,
  <a
    href={"https://helpcenter.wesparkle.ai/article/Privacy-Policy"}
    onClick={() =>
      segmentAction.track("Homepage Contact Click", { value: "privacy" })
    }
  >
    Privacy
  </a>,
  <Link
    to={"/press"}
    onClick={() =>
      segmentAction.track("Homepage Contact Click", { value: "press" })
    }
  >
    Press
  </Link>,
]

const footerElems = [
  <a
    href={"mailto:info@wesparkle.co"}
    onClick={() =>
      segmentAction.track("Homepage Contact Click", { value: "email" })
    }
  >
    info@wesparkle.co
  </a>,
  <a
    href={"tel:+1651-349-4918"}
    onClick={() =>
      segmentAction.track("Homepage Contact Click", { value: "phone" })
    }
  >
    651.349.4918
  </a>,
  <>
    <span style={{ whiteSpace: "nowrap" }}>825 Washington Ave SE</span>{" "}
    <span style={{ whiteSpace: "nowrap" }}>#200 Minneapolis, MN 55414</span>
  </>,
]

const PageContact = () => {
  const year = new Date().getFullYear()
  return (
    <Container>
      <PageContactInfo>
        <PageContactSocials>
          <h3>Follow Us</h3>
          <p style={{ fontSize: "1rem", lineHeight: "1.5", padding: "1rem 0" }}>
            We keep things positive, and help you sparkle! Follow along for
            entrepreneurial tips and feature updates.
          </p>
          <div style={{ marginTop: "1rem" }}>
            {socials.map(({ name, url }, i) => {
              return (
                <SocialHover
                  key={i}
                  onClick={() => {
                    segmentAction.track(`Homepage Social Media Click`, {
                      value: name,
                    })
                  }}
                  style={{ marginRight: 20 }}
                  as={"a"}
                  href={url}
                  target={"_blank"}
                >
                  <IconLinks name={name} style={{ width: 30, height: 30 }} />
                </SocialHover>
              )
            })}
          </div>
          {/* <Link
        to="/press"
        style={{fontSize: "0.85rem", marginTop: "1rem", display: "block"}}>
          Press & Publicity
        </Link> */}
        </PageContactSocials>
        <PageContactNewsletter />
      </PageContactInfo>
      <div
        style={{
          marginTop: "20vh",
          fontSize: ".8em",
        }}
      >
        <p style={{ display: "flex", alignItems: "end", marginBottom: 40 }}>
          <span style={{ position: "relative" }}>
            <IconMN
              style={{
                width: 100,
                height: "auto",
                fill: "#fff",
              }}
              alt={"Minnesota State"}
            />
            <IconHeart
              style={{
                position: "absolute",
                left: 19,
                top: 40,
                width: 36,
                height: "auto",
                fill: "var(--sparkle-red)",
              }}
              alt={"heart"}
            />
          </span>
          <span>
            ©{year} We Sparkle Co.
            <br /> Made with love in Minnesota
            <br />
            Mni Sota Makoce, Dakota and Anishinaabe land
          </span>
        </p>
        <p>
          We Sparkle Co. is a CERT Small/Minority/Woman-owned public benefit
          corporation
        </p>
        <p style={{ lineHeight: 2.4 }}>
          {footerElems.map((el, i) => {
            return (
              <Fragment key={i}>
                {el}
                {i !== footerElems.length - 1 ? " • " : ""}
              </Fragment>
            )
          })}
          {/* <a>info@wesparkle.co</a> • <a href={""}>651.349.4918</a> • 825
          Washington Ave SE #200 Minneapolis, MN 55414 */}
        </p>
        <p style={{ lineHeight: 2.4 }}>
          {termsElems.map((el, i) => {
            return (
              <Fragment key={i}>
                {el}
                {i !== termsElems.length - 1 ? " • " : ""}
              </Fragment>
            )
          })}
        </p>
      </div>
    </Container>
  )
}

export default PageContact
