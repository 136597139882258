import React, { useState } from "react"
import axios from "axios"
import { ButtonPrimary } from "./Buttons"
import styled from "styled-components"
import IconLoaderCircle from "../icons/IconLoaderCircle"
import { segmentAction } from "../hooks/useSegmentTrack"

const Form = styled.form`
  input {
    width: 100%;
    padding: 0.65em 0.75em;
    padding-left: 1em;
    border-radius: 100px;
    font-size: 1.1rem;
    display: block;
    margin-bottom: 0.75em;
    ::placeholder {
      color: #000;
    }
  }
  .first-name {
    width: 48%;
    margin-right: 4%;
  }
  .last-name {
    width: 48%;
  }
  @media screen and (max-width: 900px) {
    .first-name, .last-name, .email {
      width: 100%;
      margin: 1rem 0 0;
    }
  }
`

const PageContactNewsletter = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)
  const [inProgress, setInProgress] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()
    segmentAction.track("Homepage Newsletter Form Submit")
    setInProgress(true)
    // If success show this stuff + clear out
    // api call
    const baseURL = process.env.GATSBY_SPARKLE_API_PATH
    const apiCode = process.env.GATSBY_SPARKLE_API_CODE
    const api = axios.create({ baseURL })
    api
      .post(`NewsletterAddRecipient?code=${apiCode}`, { email, firstName, lastName })
      .then((response) => {
        setSuccess(true)
        setInProgress(false)

        // Keep this timeout around after call... reset to allow more newsletter sign-ups
        setTimeout(() => {
          setSuccess(false)
          setEmail("")
        }, 5000)
      })
      .catch(() => {
        // TODO: Handle error
        console.error("Newsletter did not work...")
        setSuccess(false)
        setInProgress(false)
        setEmail("")
      })
  }

  return (
    <Form onSubmit={onSubmit}>
      <p style={{ marginBottom: "0.75rem"}}><strong>We also make emails</strong></p>
      <p style={{ position: "relative", fontSize: "1rem", lineHeight:"1.5", padding:"0 0 0.75rem", marginBottom: "0.75rem" }}>
        <span
          style={{
            opacity: !success ? 1 : 0,
            transition: ".2s",
            transform: !success ? "translateY(0)" : "translateY(5px)",
          }}
        >
          Receive We Sparkle updates!
        </span>
        <span
          style={{
            width: "100%",
            left: 0,
            position: "absolute",
            color: "var(--sparkle-green)",
            opacity: success ? 1 : 0,
            transition: ".2s",
            transform: success ? "translateY(0)" : "translateY(5px)",
          }}
        >
          Yay! Successfully added to newsletter!
        </span>
      </p>
      <label
        style={{
          pointerEvents: inProgress || success ? "none" : "",
          position: "relative",
          display: "inline-block",
        }}
        className="first-name"
      >
        <input
          type={"firstName"}
          name={"firstName"}
          placeholder={"First Name"}
          required
          onChange={(e) => {
            const value = e.target.value
            setFirstName(value)
          }}
          value={firstName}
        />
      </label>
      <label
        style={{
          pointerEvents: inProgress || success ? "none" : "",
          position: "relative",
          display: "inline-block",
        }}
        className="last-name"
      >
        <input
          type={"lastName"}
          name={"lastName"}
          placeholder={"Last Name"}
          required
          onChange={(e) => {
            const value = e.target.value
            setLastName(value)
          }}
          value={lastName}
        />
      </label>
      <label
        style={{
          pointerEvents: inProgress || success ? "none" : "",
          position: "relative",
          display: "block",
        }}
        className="email"
      >
        <input
          type={"email"}
          name={"email"}
          placeholder={"Email"}
          required
          onChange={(e) => {
            const value = e.target.value
            setEmail(value)
          }}
          value={email}
        />
      <ButtonPrimary
        type={"submit"}
        style={{
          pointerEvents: success || inProgress ? "none" : "",
          position: "absolute",
          right: 6,
          top: "50%",
          width: 40,
          height: 40,
          padding: 0,
          background: success ? "var(--sparkle-drkgreen)" : "",
          transform: "translateY(-50%)",
        }}
      >
        {/* Quick little arrow */}
        {!success && !inProgress && (
          <svg
            viewBox={"0 0 1 1"}
            stroke={"#fff"}
            strokeWidth={0.25}
            strokeLinecap={"round"}
            style={{
              overflow: "visible",
              width: 9,
              transformOrigin: "center",
              transform: "rotate(-45deg)",
            }}
          >
            <path d={"M0,0 1,1"} />
            <path d={"M0,1 1,1"} />
            <path d={"M1,0 1,1"} />
          </svg>
        )}
        {/* Loading */}
        {inProgress && (
          <IconLoaderCircle
            style={{ marginLeft: 10 }}
            indicator={"#fff"}
            widthPercent={0.17}
            bg={"rgba(255,255,255,.5)"}
            length={20}
          />
        )}
        {/* Checkmark */}
        {success && (
          <svg
            viewBox={"0 0 1 1"}
            stroke={"#fff"}
            strokeWidth={0.25}
            fill={"none"}
            strokeLinejoin={"round"}
            strokeLinecap={"round"}
            style={{
              overflow: "visible",
              width: 12,
            }}
          >
            <path d={"M0,.7 .4,1 1,.2"} />
          </svg>
        )}
      </ButtonPrimary>
      </label>
    </Form>
  )
}

export default PageContactNewsletter
