import styled from "styled-components"
import chroma from "chroma-js"

const PageNavAccountLogoArea = styled.div`
  transition: opacity 0.2s;
  opacity: 1;
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  box-shadow: 0 0 0 2px #fff, 0 0 0px 4px ${chroma("#3790E2").alpha(0.4)}; /* TODO: this will be from the tenant at some point */
  overflow: hidden;

  &.account-desktop {
    display: block;
  }
  &.account-mobile {
    display: none;
  }

  @media screen and (max-width: 900px) {
    &.account-desktop {
      display: none;
    }
    &.account-mobile {
      display: block;
    }
  }
`

export default PageNavAccountLogoArea