import axios from "axios"
import queryString from "query-string"
const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
const apiCode = process.env.GATSBY_SPARKLE_API_CODE

// MORE UTILS for clicking and starting site :)... not really a hook
const onPaidPlanHandler = ({
  planCode,
  user,
  setLoadingPurchaseUrl,
  text,
  queryStringParsed,
}) => {
  const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
  api
    .post(`GetSubscriptionLinks?code=${apiCode}`, {
      productCode: planCode,
      tenantId: user ? user["https://wesparkle.biz/sparkleTenantId"] : null,
      userId: user ? user.sub : null,
      siteName: text || "",
      queryStrings: queryStringParsed, // For passing along info like like utm codes for tracking landing pages + campaigns
    })
    .then((response) => {
      setLoadingPurchaseUrl(false)
      let purchaseUrl
      // Basic purchase link
      if (response.data.BASIC) {
        purchaseUrl = response.data.BASIC.hostedpage.url
      }
      // Annual purchase link
      if (response.data["BASIC-ANNUAL"]) {
        purchaseUrl = response.data["BASIC-ANNUAL"].hostedpage.url
      }
      // Business purchase link
      if (response.data.BUSINESS) {
        purchaseUrl = response.data.BUSINESS.hostedpage.url
      }
      // Annual Business purchase link
      if (response.data["BUSINESS-ANNUAL"]) {
        purchaseUrl = response.data["BUSINESS-ANNUAL"].hostedpage.url
      }
      if (purchaseUrl && window) {
        window.location.assign(purchaseUrl)
      }
    })
    .catch((error) => {
      console.log(error)
      setLoadingPurchaseUrl(false)
    })
}

export const onSignUpClickStart = ({
  text,
  success,
  plan,
  monthly,
  setLoadingPurchaseUrl,
  user,
}) => {
  const validOrEmptySiteName = (text && success) || !text
  const qs = (window && window.location && window.location.search) || ""
  const queryStringParsed = queryString.parse(qs)
  if (validOrEmptySiteName && plan !== null && plan !== undefined) {
    switch (plan) {
      case 0: {
        // Free plan
        if (window)
          window.location.assign(
            `${process.env.GATSBY_SPARKLE_ADMIN_PATH}?signUp=true&siteName=${
              text || ""
            }&${qs.replace(/^\?/, "")}` // For passing along info like like utm codes for tracking landing pages + campaigns
          )
        return
      }
      case 1: {
        // Scheduler
        setLoadingPurchaseUrl(true)
        onPaidPlanHandler({
          planCode: monthly ? "BASIC" : "BASIC-ANNUAL",
          user,
          setLoadingPurchaseUrl,
          queryStringParsed,
          text,
        })
        return
      }
      case 2: {
        // Assistant
        setLoadingPurchaseUrl(true)
        onPaidPlanHandler({
          planCode: monthly ? "BUSINESS" : "BUSINESS-ANNUAL",
          user,
          setLoadingPurchaseUrl,
          queryStringParsed,
          text,
        })
        return
      }
      default: {
        console.error("Type not allowed")
        return
      }
    }
  }
}
