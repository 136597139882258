import React from "react"
import styled from "styled-components"
import TenantLogo from "../core/TenantLogo"
import LogoArea from "./PageNavAccountLogoArea"
import useTenantSignedInContext from "../hooks/useTenantSignedInContext"
import { segmentAction } from "../hooks/useSegmentTrack"

const AccountDropdown = styled.div`
  display: none;
  .dropdown-container {
    padding: 0;
    max-width: 200px;
    min-width: 150px;
    font-size: 0.9rem;
  }
  .tenant-logo {
    width: 33px;
    height: 33px;
    margin-right: 10px;
  }
  .tenant-info {
    flex: 1;
  }

  @media screen and (max-width: 900px) {
    display: none;

    &.account-dropdown.is-expanded {
      display: block;
      position: absolute;
      top: 52px;
      left: 0;
      width: 100%;
      height: calc(100vh - 52px);
      background-color: white;
      z-index: 1000;

      .dropdown-container {
        max-width: 100% !important;
        min-width: unset !important;
        margin: 1rem;
        padding: 0 var(--content-pad);
      }

      .tenant-logo {
        width: 65px !important;
        height: 65px !important;
      }

      .tenant-links {
        padding: 0;
      }
    }
  }
`
const LoggedInContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  line-height: 1.2;

  &:hover {
    .account-dropdown {
      display: block;
    }
  }
  @media screen and (max-width: 900px) {
    border: none;
    padding: 0;
    gap: 1rem;
  }
`

const Links = styled.div`
  display: block;
  padding: 0.85rem;
  cursor: pointer;
  transition: 0.05s;
  text-transform: none;
  font-weight: normal;

  :link,
  :visited {
    color: inherit;
  }
  :hover {
    color: ${({ theme }) => theme.colors.accent};
  }
  &:first-child {
    padding-bottom: 0.85rem 0.85rem 0;
  }
  &:last-child {
    border-top: 1px solid #eee;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.25rem;
    font-weight: bold;
    padding: 2rem 0;
    border-bottom: 1px solid #ced3db;

    &:last-child {
      border: none;
    }
  }
`

const PageNavAccountDropdown = ({
  tenantInfo,
  trackingPrefix, // Homepage vs Sparkles Page
  isAccountMobileExpanded, // used to style mobile account dropdown
  setIsAccountDesktopExpanded,
}) => {
  const { onLogout, homepageUrl, adminUrl, contactUrl } =
    useTenantSignedInContext()
  return (
    <AccountDropdown
      className={
        isAccountMobileExpanded
          ? "account-dropdown is-expanded"
          : "account-dropdown"
      }
      onMouseEnter={() => {
        setIsAccountDesktopExpanded(true)
      }}
      onMouseLeave={() => {
        setIsAccountDesktopExpanded(false)
      }}
    >
      <div className="dropdown-container">
        <LoggedInContainer>
          <LogoArea className="tenant-logo">
            <TenantLogo tenantInfo={tenantInfo} />
          </LogoArea>
          {/* This isn't necessarily true... need to get our logged in tenant info */}
          <div className="tenant-info">
            <strong>{tenantInfo.tenantName}</strong>
          </div>
        </LoggedInContainer>
        <div className="tenant-links">
          <Links
            as={"a"}
            href={homepageUrl}
            onClick={() => {
              segmentAction.track(
                `${trackingPrefix} Login Panel To Own Site Click`
              )
            }}
          >
            My Site
          </Links>
          <Links
            as={"a"}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => {
              segmentAction.track(
                `${trackingPrefix} Login Panel Settings Click`
              )
            }}
            href={adminUrl}
          >
            Settings
          </Links>
          <div
            style={{
              paddingInline: "1rem",
              borderTop: "1px solid #eee",
            }}
          >
            <Links as={"a"} href={contactUrl} target={"_blank"}>
              Contact Us
            </Links>
          </div>
          <div
            style={{
              padding: ".5rem 1rem ",
              fontWeight: "bold",
            }}
          >
            <Links
              onClick={() => {
                segmentAction.track(`${trackingPrefix} Login Panel Logout`)
                onLogout()
              }}
            >
              Log out
            </Links>
          </div>
        </div>
      </div>
    </AccountDropdown>
  )
}

export default PageNavAccountDropdown
